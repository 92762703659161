<script setup lang="ts">
import PageLayout from '@/layouts/PageLayout.vue';
import { onMounted, provide, ref } from 'vue';
import {
  HeroArea,
  NavigationTabs,
  AboutMeSection,
  RatingSection,
  ArticlesSection,
  CompanyInformation,
  LegalProducts,
  ContactInformation,
  ImprintSection,
  FAQSection,
} from '@/modules/profile-page/components/Sections/index';
import AbsenceMode from '@/modules/profile-page/components/Badges/AbsenceMode.vue';
import BackToTop from '@/modules/profile-page/components/Buttons/BackToTop.vue';
import MobileSidebar from '@/shared/components/SectionMobileSidebar.vue';
import { PROFILE_DIRECT_MESSAGE_SOURCE } from '@/shared/constants/SourceType';
import SectionSidebar from '@/shared/components/SectionSideBar.vue';
import { ScrollHelper } from '@/modules/profile-page/helpers/ScrollHelper';
import { RATE_LINK } from "@/modules/profile-page/constants/Links";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const isVisible = ref(false);

provide('direct-message-source-type', PROFILE_DIRECT_MESSAGE_SOURCE);
provide('give-rating-link', RATE_LINK);
provide('give-rating-ga-label', t('eventLabelProfile'));

onMounted(() => {
  ScrollHelper().watchElement(document.getElementsByTagName('figure')[0], (value) => {
    isVisible.value = value;
  });
});
</script>

<template>
  <PageLayout>
    <template #top></template>
    <template #main>
      <NavigationTabs class="anw-column-screen anw-grid-layout md:hidden" />
      <div class="anw-column-screen anw-grid-layout bg-white py-6">
        <AbsenceMode />
        <HeroArea />
      </div>
      <NavigationTabs class="anw-column-screen anw-grid-layout hidden md:grid" />
      <div class="mt-6 flex flex-col gap-4">
        <AboutMeSection />
        <RatingSection />
        <ArticlesSection />
        <LegalProducts />
        <CompanyInformation />
        <ContactInformation />
        <ImprintSection />
      </div>
    </template>

    <template #aside>
      <MobileSidebar :linkedProfile="false" :isAvatarVisible="false" class="anw-column-screen" />
      <div class="anw-column-aside hidden xl:block">
        <div class="anw-column-aside hidden xl:block">
          <div class="absolute pt-6 right-[calc((100vw_-_1180px)_/_2)] w-[378px] inset-y-0">
            <SectionSidebar :linkedProfile="false" :isVisible="isVisible" class="sticky top-16 z-20 xl:grid xl:gap-3" />
          </div>
        </div>
      </div>
    </template>

    <template #bottom>
      <FAQSection />
    </template>

    <template #backToTop>
      <BackToTop />
    </template>
  </PageLayout>
</template>

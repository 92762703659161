import { useBaseRatingStore } from '@/shared/stores/BaseRatingStore';
import { RatingSummary } from '@shared/types/RatingSummary';
import { ReviewList } from '@shared/types/Review';

export const initRatingSummary = (rating: RatingSummary, reviewList: ReviewList) => {
  const ratingStore = useBaseRatingStore();

  ratingStore.$patch((state) => {
    state.rating = { ...rating };
    state.reviewList = [...reviewList];
  });
};

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons';
import { AddressHelper } from '../../helpers/AddressHelper';
import { ScrollHelper } from '../../helpers/ScrollHelper';
import { NAVBAR_ITEMS } from '@/modules/profile-page/constants/Navbar';
import DistanceInKm from './DistanceInKm.vue';
import ExternalWebsite from './ExternalWebsite.vue';
import { useI18n } from 'vue-i18n';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';

const { getMainAddress } = AddressHelper();
const { scrollToSection } = ScrollHelper();
const { t } = useI18n();
const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkCityVCard'),
});
</script>

<template>
  <address class="flex gap-4 md:gap-2 not-italic">
    <FontAwesomeIcon
      :icon="faLocationDot"
      class="text-[14px] text-neutral-800 pt-1.5 shrink-0"
      aria-hidden="true"
      fixed-width />
    <div class="grid gap-2">
      <div>
        <div>
          <button
            type="button"
            v-bind="dataAttributesGA"
            @click="scrollToSection(NAVBAR_ITEMS.CONTACT)"
            class="text-sm/[22px] text-info-500 font-semibold hover:underline">
            {{ getMainAddress() }}
          </button>
        </div>
        <DistanceInKm />
      </div>
      <ExternalWebsite />
    </div>
  </address>
</template>

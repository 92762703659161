<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowUpRightFromSquare, faGlobe } from '@fortawesome/pro-light-svg-icons';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { statisticKey } from '@/shared/constants/ProviderKeys';
import { inject } from 'vue';
import { COUNTER_TYPES } from '@shared/types/statistic/CounterType';
import { useBaseProfileStore } from '@/shared/stores/BaseProfileStore';
import { storeToRefs } from 'pinia';

defineProps<{ dataContactValue: string }>();

const { userId } = storeToRefs(useBaseProfileStore());
const statistic = inject(statisticKey);

const { t } = useI18n();

const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkHomepage'),
  [GA_ATRIBUTES.LABEL]: t('eventLabelProfile'),
});
</script>

<template>
  <p class="flex gap-3 text-left items-center">
    <span class="flex justify-center w-5 shrink-0">
      <FontAwesomeIcon :icon="faGlobe" class="fa-1x mt-0.5" aria-hidden="true" />
    </span>
    <a
      @click="statistic?.sendCounter({ counterType: COUNTER_TYPES.COUNTER_TYPE_PROFILE_HOMEPAGE, entityId: userId })"
      :href="dataContactValue"
      v-bind="dataAttributesGA"
      target="_blank"
      rel="noopener noreferrer nofollow"
      class="inline-flex gap-2 items-center rounded focus:outline-none focus:ring font-semibold text-base">
      <span class="break-all">
        <span class="mr-1">{{ dataContactValue }}</span>
        <FontAwesomeIcon :icon="faArrowUpRightFromSquare" class="text-[14px]" aria-hidden="true" />
      </span>
      <span class="sr-only">{{ t('openNewTab') }}</span>
    </a>
  </p>
</template>

<script setup lang="ts">
import { AnwAccordion } from '@anwalt.de/tailwind-design-system';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { faVideo } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { useI18n } from 'vue-i18n';
import { useSettingsStore } from '@/shared/stores/SettingsStore';

const { offersOnlineAdvice, onlineAdviceText } = useSettingsStore();
const { t } = useI18n();
</script>
<template>
  <div v-if="offersOnlineAdvice">
    <AnwAccordion
      v-if="offersOnlineAdvice"
      class="border-t border-neutral-200 first:border-t-0"
      :label="t('sidebarOnlineHeading')">
      <template #icon>
        <fontAwesomeLayers class="text-[34px]">
          <FontAwesomeIcon :icon="faCircle" class="text-[34px] text-success-100" aria-hidden="true" />
          <FontAwesomeIcon :icon="faVideo" class="text-[14px] text-success-600" aria-hidden="true" />
        </fontAwesomeLayers>
      </template>
      <span v-html="onlineAdviceText"></span>
    </AnwAccordion>
  </div>
</template>
<style>
.fake-class-to-render-rating-css {
  color: red;
}
</style>

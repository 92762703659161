<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { DIRECT_MESSAGE } from '../../constants/Links';
import { useUserStore } from '../../stores/UserStore';
import { useSettingsStore } from '@/shared/stores/SettingsStore';
const { t } = useI18n();
const { uri } = useUserStore();
const { canReceiveMessages } = useSettingsStore();
</script>
<template>
  <div class="flex justify-center mt-6 md:mt-8">
    <a
      v-if="canReceiveMessages"
      :href="uri + DIRECT_MESSAGE"
      class="anw-btn anw-btn-primary inline-flex hover:!no-underline md:w-full xl:w-auto"
      >{{ t('faqLink') }}</a
    >
  </div>
</template>

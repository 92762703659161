<script setup lang="ts">
import TypePhone from '@/shared/components/Contact/ContactType/TypePhone.vue';
import TypeEmail from '@/shared/components/Contact/ContactType/TypeEmail.vue';
import TypeWebsite from '@/shared/components/Contact/ContactType/TypeWebsite.vue';
import { PHONES_CONTACTS, CONTACTS } from '@/shared/constants/ContactType';
import { ContactDetail } from '@shared/types/ContactDetail';

defineProps<{
  contacts: Partial<ContactDetail>;
  contactId: number;
}>();

const getComponent = (key: string) => {
  if (PHONES_CONTACTS.includes(key as CONTACTS) || key === CONTACTS.FAX_NUMBER) return TypePhone;
  if (key === CONTACTS.EMAIL) return TypeEmail;
  return TypeWebsite;
};
</script>

<template>
  <ul class="grid gap-3">
    <li v-for="(value, key, idx) in contacts" :key="idx" class="flex items-center text-neutral-900 text-base">
      <component
        :data-contact-key="key"
        :data-contact-value="value as string"
        :data-contact-id="contactId"
        :key="idx"
        :is="getComponent(key as string)" />
    </li>
  </ul>
</template>

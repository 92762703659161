import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import Lawyer from 'shared/types/Lawyer';
import Company from 'shared/types/Company';
import { useSettingsStore } from '@/shared/stores/SettingsStore';

export const initUser = (userData: Lawyer | Company) => {
  useUserStore().$patch((state) => {
    state.motto = userData.motto;
    state.about = userData.about;
    state.languageList = [...userData.languageList];
    state.uri = userData.uri;
    state.imagePath = userData.imagePath;
    state.imprint = userData.imprint;
    state.internationalLawList = [...userData.internationalLawList];
  });

  useSettingsStore().$patch((state) => {
    state.isNew = userData.isNew;
    state.offersOnlineAdvice = userData.offersOnlineAdvice;
    state.onlineAdviceText = userData.onlineAdviceText;
    state.canReceiveMessages = userData.canReceiveMessages;
  });
};

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { CompanyDetails } from 'shared/types/CompanyDetails';

export const useCompanyDetailsStore = defineStore('companyDetails', () => {
  const companyDetails = ref<CompanyDetails | undefined>();

  const hasKap = computed(() => !!(companyDetails.value?.uri && companyDetails.value?.active));

  return { companyDetails, hasKap };
});

import { onMounted, ref, Ref } from 'vue';
import faqText from '@/modules/profile-page/assets/faqText.json';
import { NAVBAR_ITEMS } from '@/modules/profile-page/constants/Navbar';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { useUserStore } from '../stores/UserStore';
import { useBaseLawyerStore } from '@/shared/stores/BaseLawyerStore';
import { SEX } from '@shared/types/Sex';
import { TranslationHelper } from '@/shared/helper/TranslationHelper';
import { COMPANY } from '@shared/constants/ProfileProductId';
import { WorkingTime } from '@shared/types/WorkingTime';
import { OpeningHours } from '@/shared/helper/OpeningHoursHelper';
import { objectEntries } from '@vueuse/core';
import { useSettingsStore } from '@/shared/stores/SettingsStore';
import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';

export const useFaq = () => {
  const { profileProductId } = useProfileProductId();
  const { displayedName } = useUserStore();

  const { profile } = useBaseLawyerStore();
  const { canReceiveMessages } = useSettingsStore();
  const { getTranslationByProductId } = TranslationHelper();

  const faqs: Ref<{ [key: string]: string }[]> = ref([]);

  onMounted(() => {
    generateFaqText();
  });

  const replaceVariables = (text: string, variables: { [key: string]: string }): string => {
    const regex = /\[(.*?)\]/g;
    return text.replace(regex, (match, placeholder) => variables[placeholder] || match);
  };

  const workingTime = useContactDetailsStore().modifiedContactDetails[0].workingTime as WorkingTime;
  const companyOpenings = new OpeningHours(workingTime).getOpeningHours();

  const variables = {
    PRODUCTID: getTranslationByProductId('faqProfileType', profileProductId, {}),
    NAME: displayedName,
    PP: profile.sex === SEX.FEMALE ? 'ihr' : 'sein ',
    DP: getTranslationByProductId('faqDP', profileProductId, {}),
    HOURS: companyOpenings
      ? objectEntries(companyOpenings)
          .map(([key, value]) => `${value}: ${key.toString().replace(/<br \/>/g, ',')}`)
          .join('<br> ')
      : '',
  };

  const generateFaqText = () => {
    function add(faq: { id: string; label: string; content: string[] }) {
      if (faq.id === 'faqCosts' && profileProductId === COMPANY) faq.content.pop();
      if (faq.id === 'faqResponse' && !variables.HOURS) faq.content = faq.content.filter((_, index) => index !== 1);

      faqs.value.push({
        id: faq.id,
        label: replaceVariables(faq.label, variables),
        content: replaceVariables(faq.content.join(' '), variables),
      });
    }

    if (!canReceiveMessages) {
      faqText
        .filter((faq) => faq.id !== 'faqContact')
        .forEach((faq) => {
          add(faq);
        });
      return;
    }

    faqText.forEach((faq) => {
      add(faq);
    });
  };

  const accordionTogglerHelper = (excludedId: string) => {
    const allDetails = document.querySelectorAll(`#${NAVBAR_ITEMS.FAQ} details`);
    allDetails.forEach((detail) => {
      if (detail.id !== excludedId && detail instanceof HTMLDetailsElement) {
        detail.open = false;
      }
    });
  };

  return { accordionTogglerHelper, faqs };
};

<script setup lang="ts">
import { computed, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { statisticKey } from '@/shared/constants/ProviderKeys';
import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { useAppStore } from '@/shared/stores/AppStore';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { useI18n } from 'vue-i18n';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { COUNTER_TYPES } from '@shared/types/statistic/CounterType';

const statistic = inject(statisticKey);

const { userId } = storeToRefs(useUserStore());

const reformatUrl = (url: string) => url.replace(/^https?:\/\/(.*?)\/?$/, `$1`);

const { contactDetails } = storeToRefs(useContactDetailsStore());
const { contactDetailId } = storeToRefs(useAppStore());
const websiteContact = computed(() => {
  const defaultWebsite = contactDetails.value[0]?.website;
  if (!contactDetailId?.value) return defaultWebsite;
  return contactDetails.value.find((c) => c.id === contactDetailId.value)?.website ?? defaultWebsite;
});

const { t } = useI18n();
const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkHomepageVCard'),
  [GA_ATRIBUTES.LABEL]: '#userId#',
});
</script>

<template>
  <a
    @click="statistic?.sendCounter({ counterType: COUNTER_TYPES.COUNTER_TYPE_PROFILE_HOMEPAGE, entityId: userId })"
    v-if="websiteContact"
    :href="websiteContact"
    class="flex items-center gap-1"
    target="_blank"
    rel="noopener nofollow"
    v-bind="dataAttributesGA">
    <span class="text-xs pt-[1px]">{{ reformatUrl(websiteContact) }}</span>
    <FontAwesomeIcon :icon="faArrowUpRightFromSquare" class="text-[12px]" aria-hidden="true" />
  </a>
</template>

import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { ContactDetail, ModifiedContactDetail } from '@shared/types/ContactDetail';
import { ContactHelper } from '@/shared/helper/ContactHelper';
import Bugsnag, { NotifiableError } from '@bugsnag/js';

export const initContactDetail = async (contactList: ContactDetail[]) => {
  const contactDetailStore = useContactDetailsStore();
  const { modifiedContactList } = ContactHelper();

  contactDetailStore.$patch((state) => {
    state.contactDetails = [...contactList.filter((c) => c.visible)];
    state.modifiedContactDetails = [...modifiedContactList(contactList)];
  });

  useContactDetailsStore().$onAction(({ after, onError }) => {
    after((result) => {
      contactDetailStore.$patch((state) => {
        state.modifiedContactDetails = result as ModifiedContactDetail[];
      });
    });
    onError((error) => Bugsnag.notify(error as NotifiableError));
  });

  contactDetailStore.processor();
};
